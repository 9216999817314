import React from 'react';
import styled from 'styled-components';
import { graphql } from 'gatsby';
import { Layout, SEO, AppointmentSection, ServicesSection } from 'components';
import { PageTitle, ArticleBody, SectionImage, Label, RawLink } from 'ui';
import { Container, Stack, List, Avatar } from '@tymate/margaret';
import { findIndex } from 'lodash';

const LawyerWrapper = styled(Stack)`
  max-width: 200px;

  p {
    margin: 0;
    margin-top: ${({ theme }) => theme.spacing()};
    font-weight: 600;
    text-align: center;
  }
`;

const Skill = ({ data }) => {
  const lawyers = data?.allDatoCmsLawyer?.edges.filter(
    ({ node }) =>
      findIndex(
        node?.skills,
        ({ slug }) => slug === data?.datoCmsSkill?.slug,
      ) !== -1,
  );

  return (
    <Layout>
      <SEO
        title={data?.datoCmsSkill?.name}
        description={data?.datoCmsSkill?.description}
      />

      <Container variant="main">
        <Stack
          direction={{ default: 'column', desktop: 'row' }}
          gutterSize={2}
          alignX="space-between"
          size="full"
        >
          <div>
            <Label>{data?.datoCmsSkill?.areaOfLaw?.name}</Label>
            <PageTitle style={{ marginTop: 0 }}>
              {data?.datoCmsSkill?.name}
            </PageTitle>
            <ArticleBody
              id="body"
              dangerouslySetInnerHTML={{
                __html:
                  data?.datoCmsSkill?.contentNode?.childMarkdownRemark?.html,
              }}
            />
            <List
              marginTop={2}
              direction={{ default: 'column', tablet: 'row' }}
              gutterSize={2}
            >
              {(lawyers ?? []).map(({ node }, index) => (
                <LawyerWrapper
                  direction="column"
                  alignX="center"
                  as={RawLink}
                  to={`/cabinet#${node?.slug}`}
                  state={{ defaultIndex: 1 }}
                  key={index}
                >
                  <Avatar size="huge" imageUrl={node?.photo?.url} />
                  <p>En savoir + sur {node.name}</p>
                </LawyerWrapper>
              ))}
            </List>
          </div>
          <SectionImage>
            <img
              src={data?.datoCmsSkill?.picture?.url}
              alt={data?.datoCmsSkill?.picture?.alt}
            />
          </SectionImage>
        </Stack>

        <ServicesSection data={data?.datoCmsSkill?.services} />
      </Container>
      <AppointmentSection />
    </Layout>
  );
};

export const query = graphql`
  query($slug: String!) {
    datoCmsSkill(slug: { eq: $slug }) {
      slug
      name
      description
      contentNode {
        childMarkdownRemark {
          html
        }
      }
      areaOfLaw {
        name
      }
      picture {
        url
        alt
      }
      services {
        id
        name
      }
    }
    allDatoCmsLawyer(sort: { fields: position, order: ASC }) {
      edges {
        node {
          name
          slug
          jobTitle
          photo {
            alt
            url
          }
          skills {
            slug
          }
        }
      }
    }
  }
`;

export default Skill;
